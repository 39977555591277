@import "src/assets/styles/main.module";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
}

@media screen and (max-width: 990px) {
  .container {
    grid-template-columns: 1fr;
  }
}

