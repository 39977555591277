@import "src/assets/styles/main.module";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  height: 100%;
}

.title {
  font-size: 21px;
  font-family: $titleFont;
  font-weight: 700;
}