@import "src/assets/styles/main.module";

.wrap {
  position: relative;
  cursor: pointer;
  transition: 1s;
  background: $bg-dark-opacity;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}




