@import "src/assets/styles/main.module";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
}

.actionWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
}

.actionWrap > div {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
}

.boxValue {
  background: $gradient-green;
  padding: 4px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxWrap {
  transition: 0.5s;
  background: $bg-dark-opacity;
  opacity: 0.3;
  font-family: $titleFont;
}

.boxWrapActive {
  opacity: 1;
  cursor: pointer;
}



@media screen and (max-width: 820px) {
  .wrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}





