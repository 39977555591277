.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .wrap {
    flex-direction: column;
    row-gap: 15px;
    column-gap: 30px;
    align-items: center;
  }
}
