@import "src/assets/styles/main.module";

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: scroll !important;
  row-gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-100%);
  transition: 0.5s;
  padding: 40px 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.title {
  text-align: center;
}

.title img {
  max-width: 70px;
  margin: 0 auto;
}

.title h2 {
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  font-family: $titleFont;
}


.wrapActive {
  transform: translateX(0%);
}


.list {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 5px;
  padding-bottom: 50px;
}

.list li {
  list-style: none;
}

.list li:before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: $text-white;
  font-size: 12px;
  font-weight: 700;
  pointer-events: none;
  opacity: 0;
  letter-spacing: 20px;
  transition: 0.5s;
  white-space: nowrap;
}

.list li:hover:before {
  // opacity: 0.08;
  letter-spacing: 1px;
}

.list li a {
  position: relative;
  display: inline-block;
  padding: 6px 15px;
  text-decoration: none;
  background: $bg-blue-light;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 1;
  border-radius: 4px;
  font-size: 20px;
}

#admin a {
  background: green;
}

#exit a {
  background: $red-dark;
}

#trophy a {
  background: $blue;
}

#reports a {
  background: $red;
}

.list:hover li a {
  // opacity: 0;
}

.list li a:hover {
  transform: scale(1.4);
  z-index: 1000;
  background: $green-light;
  opacity: 1;
}

ul li a:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 80px;
  height: 100%;
  background: linear-gradient(90deg,transparent,rgba(255,255,255,0.8), transparent);
  transform: skewX(35deg);
  transition: 0s;
  display: none;
}

ul li a:hover:before {
  left: calc(100% + 100px);
  transition: 0.5s;
  transition-delay: 0.2s;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 50px;
}