@import "src/assets/styles/main.module";

.apiName {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 18px;
}


.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
  border-radius: 8px;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  padding: 20px;
}


.addPlayerContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 50px;
}

.buttonWrapper {
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: space-between;
  margin-top: 20px;
}


@media screen and (max-width: 990px) {
  .addPlayerContainer {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .buttonWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}