@import "src/assets/styles/main.module";

.image {
  width: 140px;
}

.container {
  text-align: center;
}


.title {
  text-transform: uppercase;
  color: $text-grey-light;
  font-family: $titleFont;
}

