.container {
  display: grid;
  row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 990px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
  }
}