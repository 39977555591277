@import "../../../../assets/styles/main.module";

.picker {
  border-radius: 4px !important;
  overflow: hidden;
}

.pickerError {
  border: 2px solid $red;
}

.picker input {
  width: 100%;
  height: 42px;
  background: $bg-dark-violet;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
  border: 2px solid transparent;
  transition: 0.5s;
  color: $white;
  outline: none !important;
  padding: 0 10px !important;
  border-radius: 8px;
}

.picker input:focus {
  padding: 0 10px !important;
  box-shadow: none !important;
}


.disabled {
  opacity: 0.3;
}
