.headerWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 20px;
}


@media screen and (max-width: 600px) {
  .headerWrap {
    flex-direction: column;
    row-gap: 20px;
  }
}


