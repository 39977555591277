@import "src/assets/styles/main.module";

.wrap {
  display: grid;
  row-gap: 30px;
  width: 100%;
  grid-template-columns: auto 700px;
  column-gap: 30px;
  margin-top: 65px;
}

.wrap h3 {
  font-size: 15px;
  font-family: $titleFont;
}

.container {
  display: grid;
  row-gap: 30px;
  padding-bottom: 30px;
  padding-top: 15px;
}

.menu {
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  box-shadow: $dark-box-shadow;
  backdrop-filter: blur(5px);
  position: relative;
  padding-bottom: 20px;
}

.menuBottom {
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  position: relative;
}

.menuBottom img {
  cursor: pointer;
}

.menu > .topMenu {
  margin-top: 114px;
}

.menuBottom > .topMenu {
  height: 100%;
}

.topMenu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.name {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
}

.topMenu > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 7px;
  font-family: $titleFont;
}

.avatar {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-family: $titleFont;
}

.icon {
  cursor: pointer;
  transition: 0.5s;
  color: $text-grey;
}

.icon:hover {
  color: $text-white;
}

.icon,
.coinIcon,
.experienceIcon,
.shop,
.inverter,
.boxIcon,
.expIcon {
  font-size: 27px;
}

.coinIcon {
  color: $green-light;
}

.expIcon {
  color: $text-grey;
}

.experienceIcon {
  color: $red-light;
}


.shop,
.inverter,
.boxIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.5s;
}

.shop:hover,
.inverter:hover {
  opacity: 1;
}

.boxIcon:hover {
  padding: 4px;
  color: $blue;
}

.boxCount {
  position: absolute;
  right: 12px;
  top: 90px;
  font-size: 12px;
  padding: 3px;
  border-radius: 50%;
  background: $red-light;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shop {
  top: 20px;
  color: $green-light;
}

.inverter {
  top: 60px;
  color: $blue-light;
}

.boxIcon {
  top: 100px;
  background: $bg-blue-default;
  padding: 5px;
  color: $blue-light;
}


@media screen and (max-width: 1024px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}