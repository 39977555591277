@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,900&display=swap');

html {
  scroll-behavior: smooth;
}
* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

div.edit {
  -ms-overflow-style: none!important; /* for Internet Explorer, Edge */
  scrollbar-width: none!important; /* for Firefox */
  overflow-y: scroll!important;
}

div.edit::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

iframe {
  display: none;
}

body {
  background: #0f1521;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

*,
h1,h2,h3,h4,h5,h6,
p, span, label{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #d7dce0;
}

ul li {
  list-style: none;
}
.recharts-tooltip-item {
  display: flex !important;
}


.react-datepicker-popper {
  z-index: 5000 !important;
}

