@import 'src/assets/styles/main.module';

.wrap {
  width: 100%;
}

.container {
  font-family: $titleFont;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: auto 100px;
  column-gap: 10px;
  align-items: flex-start;
  cursor: pointer;
}

.time {
  color: $text-grey;
  display: flex;
  justify-content: flex-end;
}


@media screen and (max-width: 560px) {
  .container {
    flex-direction: column;
    row-gap: 10px;
  }
}
