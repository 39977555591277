@import "src/assets/styles/main.module";

.buttonContainer {
  display: flex;
  align-items: center;
  column-gap: 3px;
}


.navigateIcon {
  font-size: 25px;
  cursor: pointer;
  transition: 1s;
}

.giftsIcon {
  font-size: 25px;
  cursor: pointer;
  transition: 1s;
  color: $blue-light;
}

.navigateIcon:hover  {
  transform: translateY(-3px);
}

.navigateIconBack {
  color: $red-light;
}

.navigateIconNext {
  color: $green-light;
}

.list {
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.6);
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.title {
  column-gap: $text-white;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  font-family: $titleFont;
}


