@import "src/assets/styles/main.module";

.list {
  display: grid;
  row-gap: 30px;
}

.title {
  font-family: $titleFont;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.icon {
  color: $green;
}


.item {
  font-family: $textFont;
}