@import "src/assets/styles/main.module";

.wrap {
  padding: 20px;
  border-radius: 8px;
  background: $grey;
  display: grid;
  row-gap: 30px;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  opacity: 0.3;

}

.wrapActive {
  opacity: 1;
}

.title {
  font-size: 18px;
}