.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
}

@media screen and (max-width: 860px) {
  .wrap {
    grid-template-columns: 1fr 1fr;
  }
}


@media screen and (max-width: 600px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}
