.container {
  width: 210px;
  height: 140px;
  margin: 30px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.ring {
  margin: -16px;
  width: 70px;
  height: 70px;
  border: 4px solid transparent;
  border-top: 4px solid #a50044;
  position: relative;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ring:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #a50044;
  top: 4px;
  right: 0px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(165, 0, 68, 0.7),
  0 0 0 6px rgba(165, 0, 68, 0.5),
  0 0 0 12px rgba(165, 0, 68, 0.3);
}

.ring:nth-child(2) {
  animation: animate2 2s linear infinite;
  animation-delay: -1s;
  border-top: 4px solid transparent;
  border-left: 4px solid rgba(34, 139, 34, 1);
}

.ring:nth-child(2):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(34, 139, 34, 1);
  top: initial;
  bottom: 4px;
  left: 0px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(34, 139, 34, 0.7),
  0 0 0 6px rgba(34, 139, 34, 0.5),
  0 0 0 12px rgba(34, 139, 34, 0.3);
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ring:nth-child(3) {
  animation: animate2 2s linear infinite;
  position: absolute;
  top: 20px;
  border-top: 4px solid transparent;
  border-left: 4px solid rgba(27, 59, 107, 1);
}

.ring:nth-child(3):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(27, 59, 107, 1);
  top: initial;
  bottom: 4px;
  left: 0px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(27, 59, 107, 0.7),
  0 0 0 6px rgba(27, 59, 107, 0.5),
  0 0 0 12px rgba(27, 59, 107, 0.3);
}
