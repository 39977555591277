@import "src/assets/styles/main.module";

.title {
  text-transform: uppercase;
  color: #d7dce0;
  font-family: $titleFont;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.image {
  max-width: 140px;
  margin-right: 10px;
}