@import "../../../../assets/styles/main.module";

.container {
  height: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  width: 100%;
  background: $bg-dark-violet;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
  border: 2px solid transparent;
  transition: 0.5s;
  position: relative;
}

.removeIconWrap {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;

}

.removeIconWrap:hover {
  opacity: 1;
}

.removeIcon {
  color: $red-light;
}

.containerActive {
  border: 2px solid $red-light;
}

.image {
  max-width: 200px !important;
  max-height: 200px !important;
}

