@import "src/assets/styles/main.module";

.wrap {
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  height: 540px;
  padding: 20px;
  position: relative;
  border-radius: 8px;
}

.title {
  font-family: $titleFont;
  height: 60px;
  cursor: pointer;
  font-size: 17px;
}

.imageWrap {
  position: relative;
  width: 100%;
  height: 190px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: 0.5s;
}

.wrap:hover .imageWrap {
  width: calc(100% + 40px);
  transform: translateX(-20px);
}

.content {
  margin-top: 20px;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 30px;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
}

