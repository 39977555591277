@import "src/assets/styles/main.module";

.btn
{
  position: relative;
  height: 50px;
  cursor: pointer;
}
.btn span
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.05);
  box-shadow: 0 15px 35px rgba(0,0,0,0.2);
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid rgba(255,255,255,0.1);
  border-radius: 8px;
  color: #fff;
  z-index: 1;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  text-transform: uppercase;
  font-size: 12px;
}
.btn:hover span
{
  letter-spacing: 1px;
}
.btn span::before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left,rgba(255,255,255,0.1),
  transparent);
  transform: skewX(45deg) translateX(0);
  transition: 0.5s;
}
.container .btn:hover span::before
{
  transform: skewX(45deg) translateX(200%);
}
.btn::before
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: $red-light;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}
.btn:hover::before
{
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
  transition-delay: 0.5s;

}
.btn::after
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: $red-light;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}
.btn:hover::after
{
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
  transition-delay: 0.5s;
}

.btnSuccess:before,
.btnSuccess:after {
  background: $green-light;
  box-shadow: 0 0 5px $green-light,
  0 0 15px $green-light,
  0 0 30px $green-light,
  0 0 60px $green-light;
}


.btnAction:before,
.btnAction:after {
  background: $blue-light !important;
  box-shadow: 0 0 5px $blue-light,
  0 0 15px $blue-light,
  0 0 30px $blue-light,
  0 0 60px $blue-light !important;;
}


.btnRemove:before,
.btnRemove:after
{
  background: $red-light;
  box-shadow: 0 0 5px $red-light,
  0 0 15px $red-light,
  0 0 30px $red-light,
  0 0 60px $red-light;
}


.btnDefault:before,
.btnDefault:after
{
  background: $blue-dark;
  box-shadow: 0 0 5px $blue-dark,
  0 0 15px $blue-dark,
  0 0 30px $blue-dark,
  0 0 60px $blue-dark;
}


.btnDisable  {
  opacity: 0.3;
}
