@import "src/assets/styles/main.module";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 20px;
  border-radius: 8px;
  background: $bg-dark-opacity;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
}

.title {
  text-transform: uppercase;
  font-family: $titleFont;
}

.link {
  color: $grey-light;
}
