@import "src/assets/styles/main.module";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
}

.setting {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-family: $titleFont;
}

.itemTitle {
  color: $text-grey-light;
  font-family: $titleFont;
}