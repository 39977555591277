@import "src/assets/styles/main.module";


.wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0.3;
  transition: 1s;
  cursor: pointer;
}

.wrapActive {
  opacity: 1;
}

.wrap:nth-child(1) {
  background: $green-active;
}

.wrap:nth-child(2) {
  background: $blue-light;
}

.wrap:nth-child(3) {
  background: $box-violet;
}