@import 'src/assets/styles/main.module';


.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-direction: column;
}

.boxValue {
  transition: 1s;
}


.wrapActiveGreen {
  background: $green;
}

.wrapActiveBlue {
  background: $blue-light;
}

.wrapActiveViolet {
  background: $box-violet;
}


.title {
  font-size: 32px;
  text-transform: uppercase;
}

.name {
  font-size: 20px;
  color: $grey-light;
  font-weight: 600;
  text-transform: uppercase;
}

.stadium {
  width: 220px;
  height: 380px;
}