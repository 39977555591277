@import "src/assets/styles/main.module";

.dec {
  height: 50px;
  width: 110px;
  position: absolute;
  mix-blend-mode: screen;
  filter: blur(7px);
  border-radius: 50%;
  transform: translateY(25px);
}

.wrap {
  height: 300px;
  background: $body-bg-dark;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: $dark-box-shadow;
  overflow: hidden;
}

.image {
  max-height: 70%;
  max-width: 80%;
}

.imageForm {
  transform: translateY(-45px);
}


.name {
  position: absolute;
  top: 20px;
  left: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-family: $titleFont;
}

.priceWrap {
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.price {
  font-size: 14px;
  font-weight: 700;
  font-family: $titleFont;
}

.buy {
  position: absolute;
  bottom: 00px;
  left: 0;
  background: $bg-grey;
  color: $white;
  padding: 10px 40px;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: $titleFont;
  width: 100%;
  cursor: pointer;
  transition: 1s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.buyActive {
  background: $gradient-green;
}

.buyActive:hover {
  background: $gradient-green-hover;
}


.wrapGreen {
  background-color: $box-green;
}

.wrapBlue {
  background-color: $box-blue;
}

.wrapViolet {
  background-color: $box-violet;
}