@import "src/assets/styles/main.module";

.wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: $bg-dark;
  height: 80px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 400;
  margin-bottom: 80px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.icon {
  font-size: 30px;
  opacity: 0.6;
  transition: 1s;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
  transform: rotate(90deg);
  font-size: 33px;
}

@media screen and (max-width: 500px) {
  .wrap {
    padding: 10px;
  }
}

