@import "src/assets/styles/main.module";

.wrap {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
}

.title {
  display: grid;
  grid-template-columns: 32px auto;
}

.imageWrap {
  font-family: $textFont;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  padding: 20px;
}

.image {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $textFont;
  font-weight: 700;
}

.textWrap {
  font-family: $textFont;
  box-shadow: $dark-box-shadow;
  backdrop-filter: $backdrop-filter;
  background: $bg-dark-opacity;
  padding: 20px;
}

.textArea {
  width: 100%;
  background: transparent;
  border: none !important;
  outline: none !important;
  color: $text-white;
  font-size: 20px;
  resize: none;
  font-family: $textFont;
}

@media screen and (max-width: 760px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}